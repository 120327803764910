import Echo from 'laravel-echo'

export default function () {
  const echo = useState<Echo | null>('echo', () => null)

  if (echo.value) {
    return echo.value
  }

  usePusher()

  echo.value = new Echo({
    broadcaster: 'pusher',
    key: process.env.PUSHER_APP_KEY,
    cluster: 'eu',
    forceTLS: true
  })

  return echo.value
}
